import React from 'react';
import Styles from './TitlePanel.module.scss';
import _ from 'lodash';
import TitlePanelLabel from "../label/TitlePanelLabel";
import {ArrowRight2} from "iconsax-react";

class TitlePanel extends React.Component{
    render() {
        let {children, className} = this.props
        return (
            <div className={`${Styles.Panel} ${className ? className : ''}`} {..._.omit(this.props,[`className`])}>
                {children}
                <div className={Styles.Line} />
            </div>
        )
    }
}

export default TitlePanel;
