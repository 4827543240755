import React from 'react';
import PropTypes from 'prop-types';
import {Pagination} from 'react-bootstrap';
import {observer} from 'mobx-react';
import DataGridStore from "./DataGridStore";


/**
 * 페이지 링크
 */
@observer
class PageLink extends React.Component {

    /**
     * 저장소
     * @return {DataGridStore} 저장소 프로퍼티
     */
    get store() {
        return this.props.store;
    }

    /**
     * 이 페이지 링크의 현재 페이지 가리킴 여부
     * @returns {boolean}
     */
    get isCurrentPage() {
        return this.props.page === this.store.page;
    }

    /**
     * 이 페이지링크의 클릭 처리
     */
    onClick() {
        console.log(this.props.page)
        this.props.store.onPageButtonClick(this.props.page);
    }

    render() {
        return (
            <Pagination.Item className={`${this.isCurrentPage ? 'active' : ''}`} onClick={() => this.onClick()} style={{cursor: 'pointer'}}
            >
                {this.props.label}
            </Pagination.Item>
        )
    }
}

PageLink.propTypes = {
    /**
     * 데이터 스토어
     */
    store: PropTypes.instanceOf(DataGridStore),

    /**
     * 각 페이지 링크가 이동할 페이지 번호
     */
    page: PropTypes.number,

    /**
     * 각 페이지 링크가 나타낼 라벨
     */
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.number])
};

export default PageLink;