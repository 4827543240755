import React, {Component} from 'react';
import DataGridStore from "../../components/dataGrid/DataGridStore";
import Sort from "../../components/dataGrid/Sort";
import {observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import TitlePanel from "../../components/layout/TitlePanel";
import {Badge, Button, Col, Form, FormGroup, InputGroup} from "react-bootstrap";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import Panel from "../../components/layout/Panel";
import Styles from "./Invoice.module.scss";
import axios from "axios";
import DataGrid from "../../components/dataGrid/DataGrid";
import Header from "../../components/dataGrid/Header";
import ThumbImage from "../../components/image/ThumbImage";
import {APP_UPLOAD_PATH} from "../../const/AppConst";
import {navigate} from "../../helper/HistoryHelper";
import FrameWrap from "../../components/layout/FrameWrap";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";

const dataGridStore = new DataGridStore({
    fetchUrl: `/invoice/list`,
    sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
    selectFnFromResponse: (res) => ({items: res.data.items, rowsCount: res.data.rowsCount}),
    noHeader: true
})

@observer
class InvoiceListView extends Component {

    @observable
    searchKeyword = null;
    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        dataGridStore.refresh();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.pathname &&
            prevProps.pathname.startsWith('/main/invoice/detail') &&
            this.props.pathname === '/main/invoice/list') {
            dataGridStore.fetch();
        }
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            dataGridStore.page = 1;
            this.search();
        }
    }

    search = () => {
        dataGridStore.searchCondition.set("keyword", this.searchKeyword);
        dataGridStore.refresh();
    }

    render() {
        return (
            <FrameWrap className={'w-100'}>
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <TitlePanelLabel label={`견적요청`} />
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <FormGroup>
                    <Col className={`d-flex flex-column`}>
                        <div className={'d-flex'}>
                            <Col>
                                <InputGroup>
                                    <Form.Control
                                        placeholder="고객명,연략처,주소,견적내용으로 검색"
                                        value={this.searchKeyword}
                                        onChange={(e) => {
                                            this.searchKeyword = e.target.value;
                                        }}
                                        onKeyDown={(e) => this.onKeyDown(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </div>
                    </Col>
                </FormGroup>
                <Panel className={Styles.Container}>
                    <div className={Styles.DataGridContainer}>
                        <DataGrid store={dataGridStore}
                                  keyColumn={`id`}
                                  columns={[
                                      <Header id={`index`} name={`번호`}/>,
                                      <Header id={['user','name']} name={`고객명`}/>,
                                      <Header id={['user',`tel`]} name={`연락처`}/>,
                                      <Header id={['user',`address`]} name={`주소`} printFunction={(address) => {
                                          return address?.length > 20 ? address.slice(0,19)+"..." : address;
                                      }}/>,
                                      <Header id={`content`} name={"견적내용"} printFunction={(content) => {
                                          return content?.length > 20 ? content.slice(0,19)+"..." : content;
                                      }}/>,
                                      <Header id={`createdAt`} name={`요청일자`} sortable
                                              printFunction={createdAt => createdAt && moment(createdAt).format('LLL')}/>,
                                      <Header id={`isContract`} name={`계약`}
                                              printFunction={isContract => isContract ?
                                                  <IoMdCheckmarkCircleOutline size={20} color={'#06C53B'}/> : `-`}/>
                                  ]}
                                  onRowClick={(row) => {
                                      navigate(`/main/invoice/detail/${row.id}`);
                                  }}
                        />
                    </div>

                </Panel>
            </FrameWrap>
        );
    }
}

export default InvoiceListView;