import {computed, makeObservable, observable, values} from 'mobx';

class DataSet {

    constructor() {
        makeObservable(this);
    }

    @observable itemMap = new Map();

    originMap = new Map();

    @observable statusMap = new Map();

    @computed
    get items(){
        return values(this.itemMap);
    }

    @observable itemsCount = 0;

    @computed
    get statuses(){
        return values(this.statusMap);
    }

    clear() {
        this.itemMap.clear();
        this.statusMap.clear();
        this.originMap.clear();
        this.itemsCount = 0;
    }
}

export default DataSet;
