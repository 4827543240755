import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {observable, toJS} from 'mobx';
import Row from './Row.js';
import Pagination from './Pagination.js';
import {Button, Form, Table} from 'react-bootstrap';
import Styles from './DataGrid.module.scss';
import {CSSTransition} from 'react-transition-group';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import {FaFileExcel} from 'react-icons/fa';
import {ColumnPanelCheckBoxGroup, ColumnPanelStore} from './ColumnPanelCheckBox.js';
import {CSVLink} from 'react-csv';
import DataGridStore from "./DataGridStore.js";


const styles ={
    menu  : (base, state) => ({
        ...base,
        zIndex:9
    }),
    container          : (base, state) => ({
        ...base,
    }),
    control            : (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(254 147 21 / 25%)' : 0,
        borderColor : state.isFocused ? '#ffc98a' : '#ced4da',
        minHeight: '0.75rem',
        '&:hover' : {
            boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(254 147 21 / 25%)' : 0,
            borderColor : state.isFocused ? '#ffc98a' : '#ced4da',
        },
        zIndex :1

    }),
    valueContainer     : base => ({
        ...base,
        padding: '0.1625rem 1rem',
    }),
    indicatorsContainer: provided => ({
        ...provided,
        padding: '0 .25rem',
    }),
    clearIndicator     : provided => ({
        ...provided,
        padding: '0 .25rem'
    }),
    dropdownIndicator  : (base, state) => ({
        ...base,
        padding: '0 .25rem'
    }),
    indicatorSeparator : (base, state) => ({
        ...base,
        width : 0,
        margin: '.25rem 0',
    }),
}

/**
 * 데이터그리드 컴포넌트
 */
@observer
class DataGrid extends React.Component {

    headerEl = createRef();
    summaryEl = createRef();

    /**
     * 컬럼 페널 스토어
     * @type {ColumnPanelStore}
     */
    @observable
    columnPanelStore;

    constructor(props, context) {
        super(props, context);
        const {columns, localStorageKey} = this.props;
        this.columnPanelStore = new ColumnPanelStore(columns || [], localStorageKey);
        columns.forEach(column =>
            this.store.colMap.set(column.key, {width: column.props.width, minWidth: column.props.minWidth})
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevColumns = prevProps.columns;
        const {columns, localStorageKey} = this.props;
        if (prevColumns != columns) {
            this.columnPanelStore = new ColumnPanelStore(columns || [], localStorageKey);
        }
    }

    /**
     * 저장소
     * @return {DataGridStore}
     */
    get store() {
        return this.props.store;
    }

    csvDownload() {

    }

    @observable
    columnPanelEl = false;

    handleColumnPanelToggle = () => {
        this.isColumnPanelOpen = !this.isColumnPanelOpen;
    };


    render() {
        let {
            columns, keyColumn, isHighlightFn, highlightClass, isCheckable = false, isSubtotal
            , isModifiable = false, downloadFilename = 'DOWNLOAD.csv', localStorageKey
            , isAddable = false, isActiveAdd = false, isDeletable = false, isSavable = false, onSave, summary, isSortable = false, filter
        } = this.props;
        let items = this.store.noPaging ? this.store.internalSortedItems : this.store.dataSet.items;
        items = items.filter(product => filter ? filter(product) : true);
        let store = this.store;

        return (

            <div className={`${this.props.className || ''} d-flex flex-column flex-default`}>
                {
                    !this.store.noHeader &&
                    <div className="d-flex justify-content-between py-1">
                        <div className="d-flex align-items-center flex-default" style={{fontSize: 12}}>
                            <ColumnPanelCheckBoxGroup columnPanelStore={this.columnPanelStore}/>
                            <CSVLink
                                headers={this.columnPanelStore.visibleColumns.map(c => ({label: c.props.name, key: c.props.id}))}
                                data={this.store.dataSet.items}
                                filename={downloadFilename}
                                className="btn btn-outline-secondary btn-sm ms-2"
                                target="_blank"
                            >
                                <FaFileExcel className="text-success"/> 엑셀다운로드
                            </CSVLink>

                            <span className="ms-2">조회건수 : {this.store.dataSet.itemsCount}</span>
                        </div>
                        {
                            (!this.store.noPaging && this.store.isChangeRowsPerPage) &&
                            <div className="d-flex">
                                <Select className="me-2"
                                        styles={styles}
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                        })}
                                        options={[
                                            {label: 10, value: 10},
                                            {label: 20, value: 20},
                                            {label: 50, value: 50},
                                        ]}
                                        onChange={({value}) => {
                                            this.store.changeRowsPerPage(value);
                                        }}
                                        value={{label: this.store.rowsPerPage, value: this.store.rowsPerPage}}
                                >
                                </Select>
                                <div className="d-flex align-items-center">
                                    개씩 보기
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="d-flex flex-column flex-default" style={{paddingBottom: this.store.noPaging ? 0 : '1rem'}}>
                    <div ref={this.headerEl}>
                        <Table className="table-hover table-striped m-0 me-1">
                            <thead>
                            <tr>
                                {
                                    // 전체 선택 버튼
                                    isCheckable &&
                                    <th>
                                        <div className={Styles.CheckBox}>
                                            <Form.Check onChange={(e) => this.store.toggleAllRows(e.target.checked)} checked={this.store.isAllChecked}/>
                                        </div>
                                    </th>
                                }
                                {
                                    this.columnPanelStore.visibleColumns.map((column, idx) => {
                                        return React.cloneElement(column,
                                            {
                                                store   : this.store,
                                                sortable: this.store.noPaging && !isSortable,
                                                scrollRef : this.scrollRef,
                                                headerRef : this.headerEl.current
                                            });
                                    })
                                }

                            </tr>
                            </thead>
                            <tbody>
                            {
                                items.length === 0 ?
                                    <tr>
                                        <td colSpan={this.columnPanelStore.visibleColumns.length}>
                                            <div >
                                                조회된 데이터가 없습니다.
                                            </div>
                                        </td>
                                    </tr> :

                                    items.map((rowData, idx) => {
                                        return (
                                            <Row key={`${rowData[keyColumn]}_${idx}`}
                                                 columns={this.columnPanelStore.visibleColumns.map(c => c.props)}
                                                 onClick={() => {
                                                     if (this.props.onRowClick) this.props.onRowClick(rowData);
                                                 }}
                                                 store={this.store}
                                                 keyColumn={keyColumn}
                                                 rowData={rowData}
                                                 highlightClass={highlightClass}
                                                 isHighlightFn={isHighlightFn}
                                                 isCheckable={isCheckable}
                                                 isModifiable={isModifiable}
                                                 isDeletable={isDeletable}
                                                 isAddable={isAddable}
                                            />
                                        );
                                    })
                            }
                            </tbody>
                        </Table>
                        <CSSTransition
                            in={this.store.isFetching}
                            timeout={200}
                            classNames="fade"
                            unmountOnExit
                        >
                            <div className={Styles.BackDrop}>
                                <Spinner animation="border" size="sm" variant="success"/>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
                {
                    !this.store.noPaging &&
                    <Pagination store={this.store}/>
                }
            </div>
        );
    }
}

DataGrid.propTypes = {
    /**
     * 데이터 스토어
     */
    store: PropTypes.instanceOf(DataGridStore).isRequired,

    /**
     * 컬럼배열
     */
    columns: PropTypes.arrayOf(PropTypes.node).isRequired,

    /**
     * 식별자 컬럼
     */
    keyColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,

    /**
     * 행 클릭시 함수
     */
    onRowClick: PropTypes.func,
    /**
     * 페이징 영역
     */
    noPagination    : PropTypes.bool,
    isHighlightFn   : PropTypes.func,
    highlightClass  : PropTypes.string,
    isCheckable     : PropTypes.bool,
    downloadFilename: PropTypes.string,
    /**
     * 컬럼 저장용 로컬 스토리지 키(메뉴ID).
     */
    localStorageKey: PropTypes.string,
};

export default DataGrid;
