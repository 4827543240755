import React from 'react';
import {FaSort, FaSortDown, FaSortUp} from 'react-icons/fa';
import Sort from './Sort.js';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import Styles from './DataGrid.module.scss';
import DataGridStore from "./DataGridStore.js";

/**
 * 데이터그리드 컬럼헤더
 */
@observer
class Header extends React.Component {

    /**
     * 스토어
     */
    get store() {
        return this.props.store;
    }

    get currentSort() {
        return this.store.sort;
    }

    set currentSort(sort) {
        this.store.sort = sort;
    }

    get isSortable() {
        return this.props.sortable || false;
    }

    /**
     * 정렬 아이콘
     * @return {*}
     */
    get sortIcon() {
        if (this.isSortable) {
            if (JSON.stringify(this.currentSort.id) === JSON.stringify(this.props.id)) {
                return this.currentSort.direction === Sort.SORT_DIRECTION.ASC ?
                    <FaSortUp/> : <FaSortDown/>
            } else {
                return <FaSort style={{fill: 'lightgray'}}/>;
            }
        } else {
            return null;
        }
    }

    onClick() {
        if (this.isSortable) {
            if (JSON.stringify(this.currentSort.id) === JSON.stringify(this.props.id)) {
                if (this.currentSort.direction === Sort.SORT_DIRECTION.ASC) {
                    this.currentSort.direction = Sort.SORT_DIRECTION.DESC;
                } else {
                    this.currentSort.direction = Sort.SORT_DIRECTION.ASC;
                }
            } else {
                this.currentSort.id = this.props.id;
                this.currentSort.direction = Sort.SORT_DIRECTION.ASC;
            }
        }

        if(!this.store.noPaging) {
            this.store.fetch();
        }

    }


    render() {
        let {store, id, scrollRef, headerRef} = this.props;
        let widthInfo = store?.colMap?.get(id);
        return (
            <th nowrap="nowrap" key={this.props.id} className={`${this.props.className} ${Styles.Header}`}  >
                <div className={`d-flex align-items-center`} onClick={() => this.isSortable  && this.onClick()} style={{...this.props.style,paddingRight:5, wordBreak:'break-word', whiteSpace:'pre'}}>
                    {this.props.name}
                    {this.isSortable && this.sortIcon}
                </div>
            </th>
        )
    }
}
Header.propTypes = {
    /**
     * 스토어
     */
    store: PropTypes.instanceOf(DataGridStore),

    /**
     * 컬럼 이름
     */
    name: PropTypes.string.isRequired,

    /**
     * 컬럼 id. 문자열이나 배열이 될 수 있다.
     */
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),

    /**
     * 각 셀용 출력함수
     */
    printFunction: PropTypes.func,

    /**
     * 정렬가능 컬럼여부
     */
    sortable: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
};

Header.defaultProps = {
    isModifiable: true
};


export default Header;
