import React from 'react';
import Cell from './Cell.js';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Button, Form} from 'react-bootstrap';
import Styles from "./DataGrid.module.scss";
import DataGridStore from "./DataGridStore.js";
/**
 * 데이터그리드 행
 */
@observer
class Row extends React.Component {

    render() {
        let {store, onClick, rowData, columns, highlightClass, isHighlightFn, isCheckable, isModifiable, isDeletable} = this.props;
        let key = rowData[store.primaryKey];
        let statusMap = store.dataSet?.statusMap?.get(key) || new Map();
        let status = statusMap?.get('status');
        const classNames = [
            status?.isDeleted ? Styles.Delete : status?.isAdded ? Styles.Create : status?.isModified ? Styles.Modify:'',
            isHighlightFn && isHighlightFn(rowData) ? highlightClass : '',
            status?.isDisabled ? Styles.Disabled : '',
        ];
        return (
            <tr className={classNames.join(' ')}
                style={{cursor : 'pointer', border: 0}}
                onClick={
                    e => {
                        onClick && onClick(rowData);
                    }
                }
            >
                {
                    isCheckable &&
                        <td className={`${Styles.Cell}`}>
                            <Form.Check checked={status?.isChecked}
                                        onClick={e =>{
                                            e.stopPropagation();
                                        }}
                                        onChange={e => {
                                            store.toggleCheckedRows(rowData, e.target.checked);
                                        }}
                            />
                        </td>
                }
                {
                    columns.map((column) => {
                            let {id, printFunction, options} = column;
                            let errorMessageMap = statusMap?.get('errorMessageMap');
                            let errorMessage = errorMessageMap?.get(column.id);
                            let cellData = DataGridStore.getCellValueByKeys(id, rowData);
                            return (<Cell store={store} errorMessage={errorMessage} key={id} id={id} cellData={cellData} rowData={rowData} printFunction={printFunction}
                                          status={status} isModifiable={isModifiable}
                                          column={column} options={options}/>)
                        }
                    )
                }

            </tr>
        )
    }
}

Row.propTypes = {
    store         : PropTypes.instanceOf(DataGridStore),
    /**
     * 컬럼
     */
    columns       : PropTypes.arrayOf(PropTypes.object).isRequired,
    keyColumn     : PropTypes.any.isRequired,
    /**
     * 행의 데이터
     */
    rowData       : PropTypes.object,
    isHighlightFn : PropTypes.func,
    highlightClass: PropTypes.string,
    onClick       : PropTypes.func,
    isCheckable   : PropTypes.bool,
};

export default Row;
