import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Styles from "./TitlePanelLabel.module.scss";
import {ArrowLeft2, ArrowRight2} from "iconsax-react";

class TitlePanelLabel extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        let{label}=this.props
        return (
            <div className={Styles.Label}>
                {label}
            </div>
        );
    }
}

export default TitlePanelLabel;