import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Frame from "../../components/layout/Frame";
import ImageListView from "./ImageListView";
import ImageDetailView from "./ImageDetailView";

class ImageManageFrame extends Component {
    render() {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/image/list' ? 'd-block' : 'd-none'} h-100`}>
                    <ImageListView pathname={location.pathname}/>
                </div>
                <Switch>
                    <Route path="/main/image/detail/:id" component={ImageDetailView}/>
                    <Redirect exact from="/main/image" to="/main/image/list"/>
                </Switch>
            </Frame>
        );
    }
}

export default ImageManageFrame;