import React, {Component} from 'react';
import {observer} from 'mobx-react';
import DataGridStore from "../../components/dataGrid/DataGridStore";
import Sort from "../../components/dataGrid/Sort";
import {Badge, Button, Col, Form, FormGroup, InputGroup, ProgressBar} from "react-bootstrap";
import axios from "axios";
import 'moment/locale/ko';
import FrameWrap from "../../components/layout/FrameWrap";
import TitlePanel from "../../components/layout/TitlePanel";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import Panel from "../../components/layout/Panel";
import Styles from "./Image.module.scss";
import {makeObservable, observable} from "mobx";
import DataGrid from "../../components/dataGrid/DataGrid";
import Header from "../../components/dataGrid/Header";
import ThumbImage from "../../components/image/ThumbImage";
import {APP_UPLOAD_PATH} from "../../const/AppConst";
import {navigate} from "../../helper/HistoryHelper";

const dataGridStore = new DataGridStore({
    fetchUrl: `/image/list`,
    sort: new Sort(`createdAt`, Sort.SORT_DIRECTION.DESC),
    selectFnFromResponse: (res) => ({items: res.data.items, rowsCount: res.data.rowsCount}),
})
@observer
class ImageListView extends Component {

    @observable
    searchKeyword = null;

    @observable
    progress = 0;

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    componentDidMount() {
        dataGridStore.refresh();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.pathname &&
            prevProps.pathname.startsWith('/main/image/detail') &&
            this.props.pathname === '/main/image/list') {
            dataGridStore.fetch();
        }
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            dataGridStore.page = 1;
            this.search();
        }
    }

    search = () => {
        dataGridStore.searchCondition.set("keyword", this.searchKeyword);
        dataGridStore.refresh();
    }

    render() {
        return (
            <FrameWrap className={'w-100'}>
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <TitlePanelLabel label={`이미지 관리`} />
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <FormGroup>
                    <Col className={`d-flex flex-column`}>
                        <div className={'d-flex'}>
                            <Col>
                                <InputGroup>
                                    <Form.Control
                                        placeholder="파일명으로 검색"
                                        value={this.searchKeyword}
                                        onChange={(e) => {
                                            this.searchKeyword = e.target.value;
                                        }}
                                        onKeyDown={(e) => this.onKeyDown(e)}
                                    />
                                </InputGroup>
                            </Col>
                        </div>
                    </Col>
                </FormGroup>
                <Panel className={Styles.Container}>
                    <Button variant={"outline-info"} onClick={() => this.ref.click()}>파일 업로드</Button>
                    <input className={`d-none`}
                           type={'file'} multiple={true} ref={ref => this.ref = ref}
                           onChange={e => {

                               let formData = new FormData();

                               Array.from(e.target.files).forEach((file, idx) =>
                                   formData.append('files', file)
                               )

                               axios.post(`/image/multiple/upload`,
                                   formData,
                                   {
                                       headers: {
                                           'Content-Type': 'multipart/form-data'
                                       },
                                       onUploadProgress: (e) => {
                                           this.progress = (e.loaded / e.total) * 100;
                                       }
                                   }
                               ).then(r => {
                                   console.log(r)
                                   dataGridStore.refresh()
                                   this.ref.value = '';
                               })
                           }}
                    />
                    <div>
                        {
                            (this.progress > 0 && this.progress < 100) &&
                            <ProgressBar striped variant="success" now={this.progress} />
                        }
                    </div>
                    <div className={Styles.DataGridContainer}>
                        <DataGrid store={dataGridStore}
                                  keyColumn={`id`}
                                  columns={[
                                      <Header id={`index`} name={`번호`}/>,
                                      <Header id={`url`} name={`미리보기`}
                                              printFunction={(url) => {
                                                  return <ThumbImage width={'4rem'} src={`${APP_UPLOAD_PATH}/${url}`}/>;
                                              }}

                                      />,
                                      <Header id={`name`} name={`파일명`}
                                              printFunction={(name) => {
                                                   return name?.length > 20 ? name.slice(0,19)+"..." : name;
                                              }}
                                      />,
                                      <Header id={`tags`} name={`태그`} printFunction={(tags) => {
                                          return <div className={'d-flex gap-1 flex-default flex-wrap'}>
                                          {
                                              tags?.map((tag, index) => {
                                                  return <Badge key={index}>{tag.name}</Badge>
                                              })
                                          }
                                          </div>
                                      }}/>,
                                      <Header id={`createdAt`} name={`생성일`} sortable
                                              printFunction={createdAt => createdAt && moment(createdAt).format('LLL')}/>,
                                  ]}
                                  onRowClick={(row) => {
                                      navigate(`/main/image/detail/${row.id}`);
                                  }}
                        />
                    </div>

                </Panel>
            </FrameWrap>
        );
    }
}

ImageListView.propTypes = {};

export default ImageListView;
