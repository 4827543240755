import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Styles from './Menu.module.scss';
import {MdArrowForwardIos} from "react-icons/md";
import {navigate} from "../../helper/HistoryHelper";
import appStore from "../../store/AppStore";

@observer
class MenuLink extends React.Component {

    render() {
        let {icon, title, to} = this.props;
        let isActive = false;
        if (to === '/')
            isActive = appStore.currentPath === '/';
        else
            isActive = appStore.currentPath.startsWith(to);

        return (
            <div className={`${Styles.MenuLinkTitle} ${isActive ? Styles.Active : ''}`}
                 onClick={() => {
                     appStore.currentPath = to;
                     navigate(to)
                 }}
            >
                <div className={'me-1'}>{icon}</div>
                <div>{title}</div>
            </div>
        );
    }
}

export default MenuLink;
