import React, {Component} from 'react';
import {observer} from "mobx-react";
import {FormStore, Input, Validation} from "../../components/validation";
import {makeObservable, observable} from "mobx";
import axios from "axios";
import FrameWrap from "../../components/layout/FrameWrap";
import {Button, Col, FormGroup, Row} from "react-bootstrap";
import {ArrowLeft2} from "iconsax-react";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import Panel from "../../components/layout/Panel";
import {goBack} from "../../helper/HistoryHelper";
import {CiImageOn} from "react-icons/ci";
import {NotificationManager} from "react-notifications";

const form = new FormStore();
@observer
class InvoiceDetailView extends Component {

    @observable
    invoice = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params && params.id) {
            this.fetchDetail(params.id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match: {params}} = this.props;
        let prevParams = prevProps.match.params;
        if (params.id !== prevParams.id) {
            if (params && params.id) {
                this.fetchDetail(params.id);
            }
        }
    }

    componentWillUnmount() {
        form.clear();
    }

    fetchDetail(id) {
        axios.post(
            `/invoice/detail/${id}`,
            {}
        ).then(res => {
           this.invoice = res.data;
            form.setValue("id",this.invoice.id);
            form.setValue("memo", this.invoice?.memo)
        });
    }

    contract = (id) => {
        axios.post(
            `/invoice/update/contract/${id}`,
            {}
        ).then( res => {
            NotificationManager.success('계약사항이 변경되었습니다.');
            this.fetchDetail(id);
        });
    }

    update = () => {
        axios.post(
            `/invoice/update`,
            {...form.values}
        ).then( res => {
            NotificationManager.success('변경되었습니다.');
            this.fetchDetail(this.invoice.id);
        });
    }

    render() {
        return (
            <FrameWrap className="w-100">
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <ArrowLeft2 size={'32'}/>
                            <TitlePanelLabel label={`${this.invoice?.user.name}`}/>
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <Panel>
                    <FormGroup>
                        <Row className={'mb-3'}>
                            <Col md={2}>고객명</Col>
                            <Col md={10}>
                                {this.invoice?.user?.name}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col md={2}>전화번호</Col>
                            <Col md={10}>
                                {this.invoice?.user?.tel}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col md={2}>주소</Col>
                            <Col md={10}>
                                {this.invoice?.user?.address}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col md={2}>견적내용</Col>
                            <Col md={10}>
                                {this.invoice?.content}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col md={2}>요청일자</Col>
                            <Col md={10}>
                                {moment(this.invoice?.createdAt).format('LLL')}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col md={2}>계약</Col>
                            <Col md={10}>
                                <Button className={'border-0 rounded-3 text-white d-flex align-items-center'}
                                        variant={this.invoice?.isContract ? 'danger' : 'success'}
                                        onClick={() => this.contract(this.invoice.id)}
                                >
                                    {this.invoice?.isContract ? '계약취소' : '계약하기'}
                                </Button>
                            </Col>
                        </Row>
                        <Row className={`d-flex align-items-md-center`}>
                            <Col md={2}>메모</Col>
                            <Col md={10}>
                                <Input store={form}
                                       style={{backgroundColor: '#fff', color: '#6C757D'}}
                                       name={`memo`}
                                       placeholder={`메모 입력`}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Panel>
                <div className={'d-flex justify-center '}>
                    <Button
                        className={'border-0 m-1'}
                        variant={'secondary'}
                        style={{
                            height: '42px',
                            backgroundColor: '#979BA6',
                            fontSize: '16px',
                            color: '#FFFFFF',
                            borderRadius: '8px'
                        }}
                        onClick={() => goBack()}
                    >
                        뒤로가기
                    </Button>
                    <Button
                        className={'border-0 m-1'}
                        variant={'primary'}
                        style={{
                            height: '42px',
                            backgroundColor: '#0085FF',
                            fontSize: '16px',
                            color: '#FFFFFF',
                            borderRadius: '8px'
                        }}
                        onClick={() => this.update()}
                    >
                        저장하기
                    </Button>
                </div>
            </FrameWrap>
        );
    }
}

export default InvoiceDetailView;