import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Frame from "../../components/layout/Frame";
import InvoiceDetailView from "./InvoiceDetailView";
import InvoiceListView from "./InvoiceListView";

class InvoiceManageFrame extends Component {
    render() {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/invoice/list' ? 'd-block' : 'd-none'} h-100`}>
                    <InvoiceListView pathname={location.pathname}/>
                </div>
                <Switch>
                    <Route path="/main/invoice/detail/:id" component={InvoiceDetailView}/>
                    <Redirect exact from="/main/invoice" to="/main/invoice/list"/>
                </Switch>
            </Frame>
        );
    }
}

export default InvoiceManageFrame;