import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Button, Col, FormGroup, Row} from 'react-bootstrap';
import {FormStore, Input, Validation} from '../../components/validation';
import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {computed, makeObservable, observable, values} from 'mobx';
import {APP_UPLOAD_PATH} from '../../const/AppConst';
import FrameWrap from "../../components/layout/FrameWrap";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import {ArrowLeft2} from "iconsax-react";
import Panel from "../../components/layout/Panel";
import AsyncCreatableSelect from "react-select/async";
import {debounce} from "lodash";
import {goBack, navigateReplace} from "../../helper/HistoryHelper";
import {confirmDialogStore} from "../../components/modal/ConfirmDialog";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {CiImageOn} from "react-icons/ci";

const form = new FormStore();

@observer
class ImageDetailView extends Component {

    @observable
    image = null;

    @observable
    selectedTagsMap = new Map();

    @observable
    inputTag;

    @computed
    get options () {
        return values(this.selectedTagsMap).map(tag => ({label: tag, value: tag}))
    }
    @computed
    get tags() {
        return values(this.selectedTagsMap).map(tag => ({ name : tag}))
    }

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params && params.id) {
            this.fetchDetail(params.id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match: {params}} = this.props;
        let prevParams = prevProps.match.params;
        if (params.id !== prevParams.id) {
            if (params && params.id) {
                this.fetchDetail(params.id);
            }
        }
    }

    componentWillUnmount() {
        form.clear();
    }

    tagSearch = debounce((inputValue, callback) => {
        axios.post(
            `/tag/search`,
            {}
        ).then(res => {
            res.data && callback(
                res.data
                    .filter(d => !this.selectedTagsMap.get(d.name))
                    .filter(d => this.inputTag && /\S/.test(this.inputTag) ? d.name.includes(this.inputTag) : true)
                    .map(ps => ({label: ps.name, value: ps.id})));
        })
    }, 200)

    handleKeyDown = (event) => {
        if (!this.inputTag) return;
        if(event.code === "Enter" && !event.nativeEvent.isComposing){
            if(this.options.find(t => t.label === this.inputTag)){
                this.inputTag = '';
                return;
            }
            this.selectedTagsMap.set(this.inputTag,this.inputTag)
            this.inputTag = '';
            event.preventDefault();
        }
    };
    fetchDetail(id) {
        axios.post(
            `/image/detail/${id}`,
            {}
        ).then(res => {
            this.image = res.data;
            form.setValue("id",this.image.id);
            form.setValue("blogUrl", this.image.blogUrl);
            form.setValue("subject", this.image.subject);
            form.setValue("content", this.image.content);
            this.image.tags.forEach(tag => this.selectedTagsMap.set(tag.name, tag.name))
        });
    }

    update = () => {
        if(!form.doCheckValid()){
            return;
        }
        form.setValue("url", this.image.url);
        form.setValue("name", this.image.name);
        axios.post("/image/update" , {...form.values, tags: this.tags})
            .then(() => {
                NotificationManager.success('변경사항이 저장되었습니다.');
                this.fetchDetail(this.image.id);
            })

    }

    delete = () => {
        if(!!this.props.match.params.id){
            confirmDialogStore.openRemove(
                '삭제',
                '삭제 하시겠습니까?',
                () => {
                    axios.post(
                        `/image/delete/${this.image.id}`,
                        {}
                    ).then(res=>{
                        NotificationManager.success('이미지가 삭제되었습니다.');
                        navigateReplace(`/main/image/list`);
                    })
                }
            )
        }
    }

    render() {
        return (
            <FrameWrap className="w-100">
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <ArrowLeft2 size={'32'}/>
                            <TitlePanelLabel label={`${this.image?.name}`} />
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <Panel>
                    <FormGroup>
                        <Row className={'mb-3'}>
                            <Col md={2}>등록일</Col>
                            <Col md={10}>
                                {moment(this.image?.createdAt).format('LLL')}
                            </Col>
                        </Row>
                        <Row className={'mb-3'}>
                            <Col md={2}>파일명</Col>
                            <Col md={10}>
                                {this.image?.name}
                            </Col>
                        </Row>
                        <Row className={`mb-3 d-flex align-items-md-center`}>
                            <Col md={2}>이미지</Col>
                            <Col md={10} className={'d-flex align-items-start gap-3'}>
                                <Zoom>
                                    <img alt={this.image?.name} width={"100"} ref={ref => this.imgRef = ref}
                                         src={`${APP_UPLOAD_PATH}/${this.image?.url}`}
                                    />
                                </Zoom>
                                <Button className={'border-0 rounded-3 text-white d-flex align-items-center'}
                                        variant={'secondary'}
                                        style={{backgroundColor: '#979BA6'}}
                                        onClick={() => this.ref.click()}
                                >
                                    <CiImageOn className={'me-1'} size={22}/> 이미지 업데이트
                                </Button>
                                <input className={`d-none`}
                                       type={'file'} ref={ref => this.ref = ref}
                                       onChange={e => {

                                           let formData = new FormData();
                                           formData.append('id', this.image?.id);
                                           formData.append('file', e.target.files[0]);
                                           axios.post(`/image/upload`,
                                               formData,
                                               {
                                                   headers: {
                                                       'Content-Type': 'multipart/form-data'
                                                   }
                                               }
                                           ).then(r => {
                                               if(r.data){
                                                   this.image.name = r.data.name;
                                                   this.image.url = r.data.url;
                                               }
                                               this.ref.value = '';
                                               NotificationManager.success('이미지가 변경되었습니다.');
                                           })
                                       }}
                                />
                            </Col>
                        </Row>
                        <Row className={`mb-3`}>
                            <Col md={2}>태그</Col>
                            <Col md={10}>
                                <AsyncCreatableSelect
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: '9999'
                                        }),
                                    }}
                                    menuPortalTarget={document.body}
                                    defaultOptions
                                    isCreatable
                                    isMulti
                                    onChange={(e) => {
                                        let map = new Map();
                                        e.map(op => map.set(op.label, op.label));
                                        this.selectedTagsMap = map;
                                    }}
                                    onInputChange={(inputTag) => this.inputTag = inputTag}
                                    onKeyDown={this.handleKeyDown}
                                    loadOptions={this.tagSearch}
                                    value={this.options}
                                    inputValue={this.inputTag}
                                />
                            </Col>
                        </Row>
                        <Row className={`d-flex align-items-md-center mb-3`}>
                            <Col md={2}>제목</Col>
                            <Col md={10}>
                                <Input store={form}
                                       style={{backgroundColor: '#fff', color: '#6C757D'}}
                                       name={`subject`}
                                       placeholder={`제목 입력`}
                                />
                            </Col>
                        </Row>
                        <Row className={`d-flex align-items-md-center mb-3`}>
                            <Col md={2}>내용</Col>
                            <Col md={10}>
                                <Input store={form}
                                       as={"textarea"}
                                       style={{backgroundColor: '#fff', color: '#6C757D', height: '10rem'}}
                                       name={`content`}
                                       placeholder={`내용 입력`}
                                />
                            </Col>
                        </Row>
                        <Row className={`d-flex align-items-md-center`}>
                            <Col md={2}>블로그 링크</Col>
                            <Col md={10}>
                                <Input store={form}
                                       style={{backgroundColor: '#fff', color: '#6C757D'}}
                                       name={`blogUrl`}
                                       placeholder={`블로그 링크 입력`}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Panel>
                <div className={'d-flex justify-center '}>
                    <Button
                        className={'border-0 m-1'}
                        variant={'secondary'}
                        style={{height: '42px', backgroundColor: '#979BA6', fontSize: '16px', color: '#FFFFFF', borderRadius: '8px'}}
                        onClick={() => goBack()}
                    >
                        뒤로가기
                    </Button>
                    <Button
                        className={'border-0 m-1'}
                        variant={'primary'}
                        style={{height: '42px', backgroundColor: '#0085FF', fontSize: '16px', color: '#FFFFFF', borderRadius: '8px'}}
                        onClick={() => this.update()}
                    >
                        저장하기
                    </Button>
                    <Button
                        className={'border-0 m-1'}
                        variant={'danger'}
                        style={{height: '42px', fontSize: '16px',  borderRadius: '8px'}}
                        onClick={() => this.delete()}
                    >
                        삭제하기
                    </Button>
                </div>
            </FrameWrap>
        );
    }
}

export default ImageDetailView;