import React from 'react';
import {observer} from 'mobx-react';
import Styles from './Menu.module.scss';
import appStore from '../../store/AppStore';

@observer
class Menu extends React.Component {

    render() {
        let {icon, title, children, to, onClick} = this.props;
        let isActive = false;
        if (to === '/')
            isActive = appStore.currentPath === '/';
        else
            isActive = appStore.currentPath.startsWith(to);
        return (
            <div className={Styles.ParentMenu}>
                <div className={Styles.Menu}>
                    <div className={`${Styles.MenuTitle} ${isActive ? Styles.Active : ''}`}
                         onClick={onClick}
                    >
                        {title}
                    </div>
                    <div>
                    </div>
                </div>
                <div className={Styles.SubMenu}>
                {children}
                </div>
            </div>
        );
    }
}

export default Menu;
