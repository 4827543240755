import React from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Styles from './MainViewFrame.module.scss';
import StatusBar from '../components/layout/StatusBar';
import SideBar from '../components/layout/SideBar';
import appStore from '../store/AppStore';
import {HISTORY} from "../helper/HistoryHelper";
import ImageListView from "./image/ImageListView";
import ImageDetailView from './image/ImageDetailView';
import ImageManageFrame from "./image/ImageManageFrame";
import InvoiceManageFrame from "./invoice/InvoiceManageFrame";

@observer
class MainViewFrame extends React.Component {

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(appStore.currentPath != HISTORY.location.pathname){
            appStore.currentPath = HISTORY.location.pathname;
        }
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className={`d-flex flex-default flex-column`}>
                {appStore.isSessionChecked && !appStore.isLogin && <Redirect to={`/login`}/>}
                {/*<StatusBar/>*/}
                <div className="d-flex flex-default flex-row">
                    <SideBar/>
                    <div className={`d-flex flex-default`} style={{backgroundColor: '#DDE4E8'}}>
                        <Switch>
                            <Route path="/main/invoice" component={InvoiceManageFrame}/>
                            <Route path="/main/image" component={ImageManageFrame}/>
                            <Redirect exact from="/main" to="/main/image"/>
                        </Switch>
                    </div>
                </div>
            </div>
        );

    }
}

export default MainViewFrame;
