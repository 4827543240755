import axios from 'axios';
import React from 'react';
import {toast} from "react-toastify";
import {navigate} from "../helper/HistoryHelper";
import appStore from "../store/AppStore";
import {NotificationManager} from "react-notifications";
export const APP_CONTEXT_PATH = process.env.REACT_APP_CONTEXT_PATH;

export const APP_UPLOAD_PATH = process.env.REACT_APP_PUBLIC_BASE_URL;

axios.defaults.baseURL = APP_CONTEXT_PATH;
axios.defaults.withCredentials = true;
axios.defaults.responseType = 'json';
axios.interceptors.response.use((response) => response, (error) => {
    let response = error.response;
    if(!response || !response.data) {
        NotificationManager.error(error);
        throw error;
    }
    NotificationManager.error(response.data.message)

    if(response.status === 401){
        appStore.isLogin = false;
        navigate("/login")
    }
    throw error;
});

/**
 * URL 메뉴 그룹 추출 정규식
 * @type {RegExp}
 */
export const MENU_GROUP_RE = /\/main\/([^\/]+)(?=\/)?/;

export const NOTIFICATION_TYPE = {
    INFO : "INFO",
    SUCCESS : "SUCCESS",
    WARNING : "WARNING",
    ERROR : "ERROR"
}

export const PUSH_TYPE = {
    ASSIGNMENT : "ASSIGNMENT"
}

export const USER_TYPE = {
    SUPER : "SUPER",
    AGENT : "AGENT",
}