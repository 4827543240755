import React from 'react';
import PropTypes from 'prop-types';
import Styles from './DataGrid.module.scss';
import {FaCheckSquare, FaRegSquare} from 'react-icons/fa';
import moment from 'moment';
import {observer} from 'mobx-react';
import _ from 'lodash';
import DataGridStore from "./DataGridStore.js";

/**
 * 데이터그리드 셀
 */
@observer
class Cell extends React.Component {

    constructor(props) {
        super(props);
    }


    /**
     * 셀의 값을 출력 한다. (혹은 포맷팅 한다.)
     * @returns {*}
     */
    toString() {
        const {column, printFunction, cellData, options} = this.props;

        if (column.type && !printFunction && cellData) {
            if (column.type === 'check')
                return cellData ? <FaCheckSquare/> : <FaRegSquare/>;

            if (column.type === 'checkNy')
                return cellData === 'Y' ? <FaRegSquare/> : <FaCheckSquare/>;

            if (column.type === 'checkYn')
                return cellData === 'Y' ? <FaCheckSquare/> : <FaRegSquare/>;

            if (column.type === 'date')
                return moment(cellData).format('YYYY-MM-DD HH:mm');

            if (column.type === 'currency')
                return <NumberFormat value={cellData} displayType="text" thousandSeparator={false}/>;

            if (column.type === 'number')
                return <NumberFormat value={cellData} displayType="text" thousandSeparator={true}/>;

            if (column.type === 'select')
                return options.find(option=>option.value == cellData)?.label;
        }

        // props 의 labelFunction 키가 라벨링 함수를 가지고 있을거라 간주.
        return !!this.props.printFunction ?
            this.props.printFunction(this.props.cellData, this.props.rowData) :
            this.props.cellData;
    };


    render() {
        let {rowData, store, id, cellData, column, errorMessage, status, isModifiable} = this.props;
        let key = rowData[store.primaryKey];
        return (
            <td nowrap="nowrap" className={Styles.Cell} style={column.style}>
                <div className={`d-flex align-items-center ${column.className}`} style={{paddingRight:5, ...column.style}}>
                    {
                        this.toString()
                    }
                </div>
            </td>
        );
    }


}

Cell.propTypes = {
    /**
     * 셀의 값
     */
    cellData: PropTypes.any,

    /**
     * 행의 데이터
     */
    rowData: PropTypes.object,

    /**
     * 출력함수
     */
    printFunction: PropTypes.func,

    /**
     *
     */
    column: PropTypes.any.isRequired

};

Cell.defaultProps = {
    options: []
};

export default Cell;
