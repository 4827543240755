import React, {Component} from 'react';
import {Button,} from 'react-bootstrap';
import {observer} from 'mobx-react';
import {FormStore, Input, Validation} from '../components/validation';
import appStore from "../store/AppStore";
import {Redirect} from "react-router-dom";
import Styles from './LoginView.module.scss'
const loginForm = new FormStore();
const store = loginForm;

@observer
class LoginView extends Component {

    componentDidMount() {
    }

    render() {
        const paddingTop = 13;
        const paddingBottom = 13;
        return (
            <div className={`d-flex w-100 h-100 a`} style={{padding:0}}>
                {appStore.isLogin && <Redirect to="/main" />}
                <div className={Styles.LoginForm} style={{backgroundImage: "url('/asset/img/bg.jpeg')"}}>
                    <div className={Styles.Form}>
                        <div className={`${Styles.Row}`}>
                            <div className={Styles.Logo}>
                                ADMIN
                            </div>
                        </div>
                        <div className={`${Styles.Row}`}>
                            <div className={Styles.Input}>
                                <Input store={store}
                                       style={{backgroundColor: '#fff', color: '#6C757D', paddingTop, paddingBottom}}
                                       label={`ID`}
                                       name={`loginId`}
                                       placeholder={`아이디를 입력`}
                                       validations={[
                                           new Validation(/^.+$/, `아이디를 입력하세요.`)
                                       ]}
                                       validOnBlur
                                />
                            </div>
                            <div className={Styles.Input}>
                                <Input store={store}
                                       style={{backgroundColor: '#fff', color: '#6C757D', paddingTop, paddingBottom}}
                                       label={`PASSWORD`}
                                       name={`password`}
                                       placeholder={`비밀번호를 입력`}
                                       type={`password`}
                                       validations={[
                                           new Validation(/^.+$/, `비밀번호를 입력하세요.`)
                                       ]}
                                       onKeyDown={(e) => {
                                           if (e.keyCode === 13) {
                                               appStore.fetchLogin({
                                                   form: loginForm.values
                                               })
                                           }
                                       }}
                                       validOnBlur
                                />
                            </div>
                        </div>
                        <div className={`${Styles.Row}`}>
                            <Button disabled={!store.valid}
                                    className={'w-100 text-white'}
                                    style={{height: '64px', backgroundColor: '#0085FF', fontSize: '20px'}}
                                    type="button"
                                    onClick={() =>
                                        appStore.fetchLogin({
                                            form: loginForm.values
                                        })
                                    }
                            >
                                로그인
                            </Button>
                        </div>
                        <div className={`${Styles.Row}`}>
                            <a style={{color: '#0085FF'}} href={"https://eback.kr"} target="_blank" >eback.kr 바로가기</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LoginView.propTypes = {};

export default LoginView;
