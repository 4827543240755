import React from 'react';
import _ from 'lodash';
import {Container} from "react-bootstrap";

class Frame extends React.Component{
    render() {
        let {children, className} = this.props
        return (
            <Container className={`h-100 w-100 flex-default p-3 ${className ? className : ''}`} {..._.omit(this.props,[`className`])}>
                {children}
            </Container>
        )
    }
}

export default Frame;
