import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Styles from './StatusBar.module.scss';
import {FaUserCircle} from "react-icons/fa";
import {GrStatusGoodSmall} from "react-icons/gr";
import {MdHome, MdMenu} from "react-icons/md";
import {NotificationManager} from "react-notifications";
import appStore from "../../store/AppStore";
import {Button} from "react-bootstrap";
import axios from "axios";
import {confirmDialogStore} from "../modal/ConfirmDialog";

@observer
class StatusBar extends React.Component {

    render() {
        return (
            <div className={Styles.Bar}>
                <div className={`d-flex`}>
                </div>
                <div className={`d-flex`}>
                    <div className={`me-2`}>
                        <Button variant={`outline-danger`}
                                onClick={() => {

                                    confirmDialogStore.openRemove('로그아웃',
                                        '로그아웃 하시겠습니까 ?',
                                        () => {
                                            appStore.fetchLogout();
                                            NotificationManager.success('로그아웃 되었습니다.')
                                        }
                                        , null,
                                        '로그아웃'
                                    )

                                }}
                                size={'sm'}
                        >
                            로그아웃
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatusBar;
