import React from 'react';
import {observer} from 'mobx-react';
import Styles from './SideBar.module.scss';
import Menu from './Menu';
import MenuLink from './MenuLink';
import appStore from '../../store/AppStore';
import { CiImageOn, CiSettings } from "react-icons/ci";
import {IoDocumentTextOutline} from "react-icons/io5";
import {confirmDialogStore} from "../modal/ConfirmDialog";
import {NotificationManager} from "react-notifications";
import {Button} from "react-bootstrap";
import {MdLogout} from "react-icons/md";
import ThumbImage from "../image/ThumbImage";

@observer
class SideBar extends React.Component {

    render() {
        return (
            <div className={Styles.Bar}>
                <div className={Styles.Profile}>
                    <ThumbImage className={'rounded-circle'} width={'50%'} src={"/asset/img/hello.png"} />
                    <div>
                        {appStore.name}
                    </div>
                    <Button className={Styles.Logout}
                            onClick={() => {

                                confirmDialogStore.openRemove('로그아웃',
                                    '로그아웃 하시겠습니까 ?',
                                    () => {
                                        appStore.fetchLogout();
                                        NotificationManager.success('로그아웃 되었습니다.')
                                    }
                                    , null,
                                    '로그아웃'
                                )

                            }}
                            size={'sm'}
                    >
                        <MdLogout color={"#FFFFFF"} />  logout
                    </Button>
                </div>
                <div className={Styles.Menu}>
                    <MenuLink title={'견적요청'} to={`/main/invoice`} icon={<IoDocumentTextOutline size={22} />}/>
                    <MenuLink title={'현장사진'} to={`/main/image`} icon={<CiImageOn size={22} />}/>
                    <MenuLink title={'설정'} to={`/main/setting`} icon={<CiSettings size={22} />}/>
                </div>

            </div>
        );
    }
}

export default SideBar;
