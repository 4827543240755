import {makeObservable, observable, toJS} from 'mobx';

class Sort {
    static SORT_DIRECTION = {ASC : 'ASC', DESC: 'DESC'};

    @observable id;
    @observable direction;


    constructor(id, direction = Sort.SORT_DIRECTION.ASC) {
        this.id = id;
        this.direction = direction;
        makeObservable(this);
    }
}

export default Sort;